import { Box, Paper, Theme, Tooltip, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { ProsignaAssayResultForm, ProsignaAssayResult } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert, PageLayout, useFullWidthLayout } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';
import OpmsFileDisplay from './input/display/OpmsFileDisplay';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface IProsignaAssayResultsProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(3, 3, 3)
    }, 
    titleContainer: {
        padding: theme.spacing(3, 3, 3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(3, 3, 3, 2)
    },
    subtitle2: {
        padding: theme.spacing(0, 3, 0)
    },
    patientInformation: {
        padding: theme.spacing(3, 3, 0)
    },
    alert: {
        padding: theme.spacing(3)
    },
    protocolDeviations: {
        padding: theme.spacing(3)
    },
    formPadding: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(3, 0)
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const prosignaAssayResultColumns: IGroupedSubformGridCell<ProsignaAssayResult>[] = [
    {
        name: 'blockId',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.blockId
    },
    {
        name: 'suitable',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.suitable
    },
    {
        name: 'successful',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.successful
    },
    {
        name: 'assayCompletionDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.assayCompletionDate
    },
    {
        name: 'subType',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.subType
    },
    {
        name: 'rorScore',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.rorScore,
        header: <Tooltip title={<>This is a stratification factor please review carefully</>}>
            <Typography>Risk of Recurrence Score</Typography>
        </Tooltip>
    },
    {
        name: 'stratificationRandomisedAs',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.stratificationRandomisedAs
    },
    {
        name: 'stratificationCorrected',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.stratificationCorrected
    },
    {
        name: 'stratificationComments',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.stratificationComments
    }
];


const hasNoProsignaAssayResults: ICondition<ProsignaAssayResultForm> = ({ formState: { value } = {} }) => !value?.prosignaAssayResults || value?.prosignaAssayResults?.length === 0;
const hasProsignaAssayResults: ICondition<ProsignaAssayResultForm> = ({ formState: { value } = {} }) => !!value?.prosignaAssayResults && value?.prosignaAssayResults?.length > 0;

const conditionSubscription = { value: true };


const ProsignaAssayResults: FunctionComponent<IProsignaAssayResultsProps> = () => {
    const { classes } = useStyles();

    useFullWidthLayout();

    return (
        <PageLayout
            breadcrumbs={<ContextMedicalReviewFormBreadcrumbs />}
            heading="Eligibility – Prosigna (PAM50) Assay Results"
        >
            <Paper>
                <div className={classes.patientInformation}>
                    <ContextPatientInformation />
                </div>
                <ContextMedicalReviewForm
                    disableEntityLowerCase
                    disableEntityTitleCase
                >
                    <Box className={classes.titleContainer}>
                        <FormDates />
                    </Box>

                    <GroupedFields>
                        <Typography variant="body1" className={classes.subtitle}>
                            <strong>To Medical Reviewer</strong>: PLEASE NOTE that the assay result (subtype and risk of recurrence score) is not to be shared with the institution.
                            <br />
                            <strong>
                                The PROSIGNA assay result was entered into OPMS by PMCC Central Laboratory staff and has been 100% source data verified by BCT staff against the uploaded result report.  
                            </strong>
                        </Typography>
                        
                        <Condition
                            type={ProsignaAssayResultForm}
                            condition={hasProsignaAssayResults}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.gridSize}>
                                <GroupedSubformGrid
                                    type={ProsignaAssayResult}
                                    name="prosignaAssayResults"
                                    columns={prosignaAssayResultColumns}
                                    disabled
                                />
                            </div>
                        </Condition>
                        <Condition
                            type={ProsignaAssayResultForm}
                            condition={hasNoProsignaAssayResults}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Prosigna Assay Results"
                                    description="There is no prosigna assay results associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    
                    <Annotations />
                    <SourceDocuments
                        fileDisplay={OpmsFileDisplay}
                        instructions={
                            <Typography variant="body1" className={classes.subtitle2}>
                                Please note the site staff are blinded to the Prosigna Assay Result.  Subtype and ROR data must not be shared with site.
                            </Typography>
                        }
                    />
                    <ContextFormQueryGrid name="queries" />
                </ContextMedicalReviewForm>
            </Paper>
        </PageLayout>
    );
};

export default ProsignaAssayResults;
