import { makeStyles } from 'tss-react/mui';
import { Paper, Theme } from '@mui/material';
import IframeResizer from 'iframe-resizer-react';
import React, { FunctionComponent } from 'react';
import { PageLayout } from '@ngt/forms-trials';

interface IIFramePageProps {
    url: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    iframe: {
        width: '100%',
        border: 'none',
        minWidth: '100%'
    }
}));

const IFramePage: FunctionComponent<IIFramePageProps> = ({ url }) => {
    const { classes } = useStyles();

    return (
        <PageLayout
            breadcrumbs={<></>}
            heading="Query Report"
        >
            <Paper>
                <IframeResizer
                    heightCalculationMethod="lowestElement"
                    inPageLinks={false}
                    log
                    src={url}
                    className={classes.iframe}
                />
            </Paper>
        </PageLayout>
    );
};

export default IFramePage;
