import { Box, Paper, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, TextDisplay, DateDisplay } from '@ngt/forms';
import { Death as DeathDto } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert, PageLayout } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';

interface IDeathProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(3, 3, 3)
    }, 
    titleContainer: {
        padding: theme.spacing(3, 3, 3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(3, 3, 3, 2)
    },
    patientInformation: {
        padding: theme.spacing(3, 3, 0)
    },
    alert: {
        padding: theme.spacing(3)
    },
    protocolDeviations: {
        padding: theme.spacing(3)
    },
    formPadding: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'right'
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasNoDeathForm: ICondition<DeathDto> = ({ formState: { value } = {} }) => !value?.eventCrfId;
const hasDeathForm: ICondition<DeathDto> = ({ formState: { value } = {} }) => !!value?.eventCrfId;

const conditionSubscription = { value: true };

const Death: FunctionComponent<IDeathProps> = () => {
    const {classes} = useStyles();

    return (
        <PageLayout
            breadcrumbs={<ContextMedicalReviewFormBreadcrumbs />}
            heading="Protocol Endpoints – Death Form"
        >
            <Paper>
                <div className={classes.patientInformation}>
                    <ContextPatientInformation />
                </div>
                <ContextMedicalReviewForm
                    disableEntityLowerCase
                    disableEntityTitleCase
                >
                    <Box className={classes.titleContainer}>
                        <FormDates />
                    </Box>
                    <Condition
                        type={DeathDto}
                        condition={hasDeathForm}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="dateOfDeath"
                            component={DateDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedFields>
                            <GroupedField
                                name="causeOfDeath"
                                component={TextDisplay}
                                GroupStyleProps={groupStyleProps}
                            />
                        </GroupedFields>
                    </Condition>
                    <Condition
                        type={DeathDto}
                        condition={hasNoDeathForm}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Death Report Form"
                                description="There is no death report form associated with this patient."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>

                    <Annotations />
                    <SourceDocuments />
                    <ContextFormQueryGrid name="queries" />
                </ContextMedicalReviewForm>
            </Paper>
        </PageLayout>
    );
};

export default Death;
