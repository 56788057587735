import { Box, Paper, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, GroupedField } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay } from '@ngt/forms';
import { BaselineToxicity as BaselineToxicityDto, AdditionalToxicity } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert, PageLayout } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import FormDates from '../../components/form/FormDates';
import Annotations from '../../components/form/Annotations';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

interface IBaselineToxicityProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(3, 3, 3)
    },
    titleContainer: {
        padding: theme.spacing(3, 3, 3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    subtitle: {
        padding: theme.spacing(3, 3, 3, 2)
    },
    patientInformation: {
        padding: theme.spacing(3, 3, 0)
    },
    alert: {
        padding: theme.spacing(3)
    },
    protocolDeviations: {
        padding: theme.spacing(3)
    },
    formPadding: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'right'
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};

const hasBaselineToxicities: ICondition<BaselineToxicityDto> = ({ formState: { value } = {} }) => value?.baselineToxicitiesPresent === true;
const hasNoBaselineToxicities: ICondition<BaselineToxicityDto> = ({ formState: { value } = {} }) => value?.baselineToxicitiesPresent === null || value?.baselineToxicitiesPresent === undefined || value?.baselineToxicitiesPresent === false;

const hasNoAdditionalToxicity: ICondition<BaselineToxicityDto> = ({ formState: { value } = {} }) => !value?.additionalToxicities || value?.additionalToxicities?.length === 0;
const hasAdditionalToxicity: ICondition<BaselineToxicityDto> = ({ formState: { value } = {} }) => !!value?.additionalToxicities && value?.additionalToxicities?.length > 0;


const additionalToxicityColumns: IGroupedSubformGridCell<AdditionalToxicity>[] = [
    //{
    //    name: 'event',
    //    content: (
    //        <Input
    //            component={OpenClinicaFormDisplay}
    //            openClinicaUrl={openClinicaUrl}
    //            eventCrfIdName="eventCrfId"
    //            studyEventIdName="studyEventId"
    //            btnMinWidth="140px"
    //            eventCrfIdPrependParentName
    //            eventCrfIdTableCell
    //            studyEventIdPrependParentName
    //            studyEventIdTableCell
    //            textValueButton
    //        />
    //    ),
    //    sortValue: (row) => row?.event
    //},
    {
        name: 'toxicityName',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.toxicityName
    },
    {
        name: 'toxicityGrade',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.toxicityGrade
    }
];

const conditionSubscription = { value: true };

const BaselineToxicity: FunctionComponent<IBaselineToxicityProps> = () => {
    const { classes } = useStyles();

    return (
        <PageLayout
            breadcrumbs={<ContextMedicalReviewFormBreadcrumbs />}
            heading="Eligibility – Baseline Toxicity Data"
        >
            <Paper>
                <div className={classes.patientInformation}>
                    <ContextPatientInformation />
                </div>
                <ContextMedicalReviewForm
                    disableEntityLowerCase
                    disableEntityTitleCase
                >
                    <Box className={classes.titleContainer}>
                        <FormDates />
                    </Box>
                    <Condition
                        type={BaselineToxicityDto}
                        condition={hasBaselineToxicities}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <GroupedField
                            name="dermatitisRadiation"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="breastPain"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="chestWallPain"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="skinInduration"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="fibrosisDeepConnectiveTissue"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="lymphoedemaBreast"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="breastInfection"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="pneumonitis"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="pulmonaryFibrosis"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="fatigue"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="chestPainCardiac"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="myocardialInfarction"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="arthralgia"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="myalgia"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="osteoporosis"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="fracture"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="vaginalDryness"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="thromboembolicEvent"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="stroke"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />
                        <GroupedField
                            name="transientIschaemicAttacks"
                            component={TextDisplay}
                            GroupStyleProps={groupStyleProps}
                        />

                        <GroupedFields>
                            <Typography variant="h2" className={classes.subtitle}>
                                Additional Toxicities
                            </Typography>

                            <Condition
                                type={BaselineToxicityDto}
                                condition={hasAdditionalToxicity}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <div className={classes.gridSize}>
                                    <GroupedSubformGrid
                                        type={AdditionalToxicity}
                                        name="additionalToxicities"
                                        columns={additionalToxicityColumns}
                                        disabled
                                    />
                                </div>
                            </Condition>
                            <Condition
                                type={BaselineToxicityDto}
                                condition={hasNoAdditionalToxicity}
                                subscription={conditionSubscription}
                                mode={ConditionMode.Show}
                            >
                                <div className={classes.alert}>
                                    <BasicAlert
                                        title="No Additional Toxicities"
                                        description="There is no additional toxicities associated with this patient."
                                        icon={faInfoCircle}
                                        severity="info"
                                    />
                                </div>
                            </Condition>
                        </GroupedFields>
                    </Condition>

                    <Condition
                        type={BaselineToxicityDto}
                        condition={hasNoBaselineToxicities}
                        subscription={conditionSubscription}
                        mode={ConditionMode.Show}
                    >
                        <div className={classes.alert}>
                            <BasicAlert
                                title="No Baseline Toxicities"
                                description="No baseline toxicities reported."
                                icon={faInfoCircle}
                                severity="info"
                            />
                        </div>
                    </Condition>

                    <Annotations />
                    <SourceDocuments />
                    <ContextFormQueryGrid name="queries" />

                </ContextMedicalReviewForm>
            </Paper>
        </PageLayout>
    );
};

export default BaselineToxicity;
