import { Box, Paper, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ContextFormTitle, ContextPatientInformation, ICondition, Condition, ContextMedicalReviewFormBreadcrumbs, ContextMedicalReviewForm, ContextFormQueryGrid, GroupedSubformGrid, IGroupedSubformGridCell, OpenClinicaFormDisplay } from '@ngt/forms-medical-review';
import { IGroupedFieldStyleProps, GroupedFields, Input, TextDisplay, DateDisplay } from '@ngt/forms';
import { NonProtocolTherapyForm, BreastSurgery, OtherBreastCancerRelatedSurgery, BreastRadiationTherapy, NonBreastRadiationTherapy, CytotoxicChemotherapy, Immunotherapy, OtherTherapy } from '../../apis/dtos';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { ConditionMode } from '@ngt/forms-core';
import { BasicAlert, PageLayout } from '@ngt/forms-trials';
import { makeStyles } from 'tss-react/mui';
import { IGroupedFieldStyleColumns } from '@ngt/forms/dist/components/form/field/grouped/GroupedField';
import SourceDocuments from '../../components/form/SourceDocuments';
import Annotations from '../../components/form/Annotations';
import FormDates from '../../components/form/FormDates';

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

const openClinicaUrl = global.OPEN_CLINICA_CRF_URL as string;

interface INonProtocolTherapyFormProps {
}

const useStyles = makeStyles()((theme: Theme) => ({
    title: {
        padding: theme.spacing(3, 3, 3)
    }, 
    titleContainer: {
        padding: theme.spacing(3, 3, 3),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    }, 
    subtitle: {
        padding: theme.spacing(3, 3, 3, 2)
    },
    patientInformation: {
        padding: theme.spacing(3, 3, 0)
    },
    alert: {
        padding: theme.spacing(3)
    },
    protocolDeviations: {
        padding: theme.spacing(3)
    },
    formPadding: {
        padding: theme.spacing(3)
    },
    buttonGroup: {
        padding: theme.spacing(3),
        textAlign: 'right'
    },
    grid: {
        padding: theme.spacing(3, 0)
    },
    gridSize: {
        fontSize: `${0.875} !important`,

        '& .MuiTypography-root': {
            fontSize: `${0.875} !important`,
        }
    }
}));

const defaultInputColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 7,
    xl: 7
}

const defaultLabelColumnSizes: IGroupedFieldStyleColumns = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 5,
    xl: 5
}

const groupStyleProps: IGroupedFieldStyleProps = {
    labelColumn: defaultLabelColumnSizes,
    inputColumn: defaultInputColumnSizes
};


const breastSurgeryColumns: IGroupedSubformGridCell<BreastSurgery>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'typeOfBreastSurgery',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.typeOfBreastSurgery
    },
    {
        name: 'surgeryDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.surgeryDate
    },
    {
        name: 'laterality',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.laterality
    },
    {
        name: 'indication',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.indication
    }
];

const otherBreastSurgeryColumns: IGroupedSubformGridCell<OtherBreastCancerRelatedSurgery>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'surgerySite',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.surgerySite
    },
    {
        name: 'surgeryDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.surgeryDate
    }
];

const breastRtColumns: IGroupedSubformGridCell<BreastRadiationTherapy>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'laterality',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.laterality
    },
    {
        name: 'startDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.startDate
    },
    {
        name: 'endDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.endDate
    },
    {
        name: 'continuing',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.continuing
    },
];

const nonBreastRtColumns: IGroupedSubformGridCell<NonBreastRadiationTherapy>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'anatomicalLocation',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.anatomicalLocation
    },
    {
        name: 'startDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.startDate
    }
];

const cytotoxicChemotherapyColumns: IGroupedSubformGridCell<CytotoxicChemotherapy>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'agentName',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.agentName
    },
    {
        name: 'startDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.startDate
    }
];

const immunotherapyColumns: IGroupedSubformGridCell<Immunotherapy>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'agentName',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.agentName
    },
    {
        name: 'startDate',
        content: (
            <Input
                component={DateDisplay}
            />
        ),
        sortValue: (row) => row?.startDate
    }
];

const otherTherapyColumns: IGroupedSubformGridCell<OtherTherapy>[] = [
    {
        name: 'eventName',
        content: (
            <Input
                component={OpenClinicaFormDisplay}
                openClinicaUrl={openClinicaUrl}
                eventCrfIdName="eventCrfId"
                studyEventIdName="studyEventId"
                btnMinWidth="140px"
                eventCrfIdPrependParentName
                eventCrfIdTableCell
                studyEventIdPrependParentName
                studyEventIdTableCell
                textValueButton
            />
        ),
        sortValue: (row) => row?.eventName
    },
    {
        name: 'other',
        content: (
            <Input
                component={TextDisplay}
            />
        ),
        sortValue: (row) => row?.other
    }
];

const hasNoBreastSurgery: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !value?.breastSurgeries || value?.breastSurgeries?.length === 0;
const hasBreastSurgery: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !!value?.breastSurgeries && value?.breastSurgeries?.length > 0;

const hasNoOtherBreastSurgery: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !value?.otherBreastCancerRelatedSurgeries || value?.otherBreastCancerRelatedSurgeries?.length === 0;
const hasOtherBreastSurgery: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !!value?.otherBreastCancerRelatedSurgeries && value?.otherBreastCancerRelatedSurgeries?.length > 0;

const hasNoBreastRt: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !value?.breastRadiationTherapies || value?.breastRadiationTherapies?.length === 0;
const hasBreastRt: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !!value?.breastRadiationTherapies && value?.breastRadiationTherapies?.length > 0;

const hasNoNonBreastRt: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !value?.nonBreastRadiationTherapies || value?.nonBreastRadiationTherapies?.length === 0;
const hasNonBreastRt: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !!value?.nonBreastRadiationTherapies && value?.nonBreastRadiationTherapies?.length > 0;

const hasNoChemo: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !value?.cytotoxicChemotherapies || value?.cytotoxicChemotherapies?.length === 0;
const hasChemo: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !!value?.cytotoxicChemotherapies && value?.cytotoxicChemotherapies?.length > 0;

const hasNoImmuno: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !value?.immunotherapies || value?.immunotherapies?.length === 0;
const hasImmuno: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !!value?.immunotherapies && value?.immunotherapies?.length > 0;

const hasNoOtherTherapy: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !value?.otherTherapies || value?.otherTherapies?.length === 0;
const hasOtherTherapy: ICondition<NonProtocolTherapyForm> = ({ formState: { value } = {} }) => !!value?.otherTherapies && value?.otherTherapies?.length > 0;

const conditionSubscription = { value: true };


const NonProtocolTherapy: FunctionComponent<INonProtocolTherapyFormProps> = () => {
    const { classes } = useStyles();

    return (
        <PageLayout
            breadcrumbs={<ContextMedicalReviewFormBreadcrumbs />}
            heading="Protocol Endpoints – Non-Protocol Therapy Form"
        >
            <Paper>
                <div className={classes.patientInformation}>
                    <ContextPatientInformation />
                </div>
                <ContextMedicalReviewForm
                    disableEntityLowerCase
                    disableEntityTitleCase
                >
                    <Box className={classes.titleContainer}>
                        <FormDates />
                    </Box>
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Breast Surgery
                        </Typography>

                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasBreastSurgery}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.gridSize}>
                                <GroupedSubformGrid
                                    type={BreastSurgery}
                                    name="breastSurgeries"
                                    columns={breastSurgeryColumns}
                                    disabled
                                />
                            </div>
                        </Condition>
                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasNoBreastSurgery}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Breast Surgery"
                                    description="There is no breast surgery associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    
                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Other Breast Cancer-related Surgery
                        </Typography>

                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasOtherBreastSurgery}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={OtherBreastCancerRelatedSurgery}
                                name="otherBreastCancerRelatedSurgeries"
                                columns={otherBreastSurgeryColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasNoOtherBreastSurgery}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Other Breast Cancer-related Surgery"
                                    description="There is no other breast cancer-related surgery associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Breast Radiation Therapy (RT)
                        </Typography>

                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasBreastRt}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={BreastRadiationTherapy}
                                name="breastRadiationTherapies"
                                columns={breastRtColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasNoBreastRt}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Breast Radiation Therapy (RT)"
                                    description="There is no breast radiation therapy associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Non-Breast Radition Therapy (RT)
                        </Typography>

                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasNonBreastRt}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={NonBreastRadiationTherapy}
                                name="nonBreastRadiationTherapies"
                                columns={nonBreastRtColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasNoNonBreastRt}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Non-Breast Radiation Therapy (RT)"
                                    description="There is no non-breast radiation therapy associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Cytotoxic Chemotherapy
                        </Typography>

                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasChemo}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={CytotoxicChemotherapy}
                                name="cytotoxicChemotherapies"
                                columns={cytotoxicChemotherapyColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasNoChemo}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Cytotoxic Chemotherapy"
                                    description="There is no cytotoxic chemotherapy associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Immunotherapy
                        </Typography>

                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasImmuno}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={Immunotherapy}
                                name="immunotherapies"
                                columns={immunotherapyColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasNoImmuno}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Immunotherapy"
                                    description="There is no immunotherapy associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>

                    <GroupedFields>
                        <Typography variant="h2" className={classes.subtitle}>
                            Other
                        </Typography>

                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasOtherTherapy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <GroupedSubformGrid
                                type={OtherTherapy}
                                name="otherTherapies"
                                columns={otherTherapyColumns}
                                disabled
                            />
                        </Condition>
                        <Condition
                            type={NonProtocolTherapyForm}
                            condition={hasNoOtherTherapy}
                            subscription={conditionSubscription}
                            mode={ConditionMode.Show}
                        >
                            <div className={classes.alert}>
                                <BasicAlert
                                    title="No Other Therapy"
                                    description="There is no other therapy associated with this patient."
                                    icon={faInfoCircle}
                                    severity="info"
                                />
                            </div>
                        </Condition>
                    </GroupedFields>
                    
                    <Annotations />
                    <SourceDocuments />
                    <ContextFormQueryGrid name="queries" />
                </ContextMedicalReviewForm>
            </Paper>
        </PageLayout>
    );
};

export default NonProtocolTherapy;
